<template>
  <div style="min-height: 97vh">
    <div class="row" style="padding-top: 45vh">
      <div class="col-12 text-center">
        <h2>Your order have been processed successfully</h2>
      </div>
      <div class="col-12 text-center" style="margin-top: 1%">
        <button
          @click="m_goToSessions"
          class="btn btn-light"
          style="font-weight: 700; font-size: 20px"
        >
          Go to sessions
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "cmp-checkoutsuccess",
  computed: {
    ...mapGetters("md_auth", ["gt_authUser"]),
  },
  async mounted() {
    if (!this.$route.params.isValid) this.$router.replace({ name: "home" });

    setTimeout(async () => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 1000);
  },
  methods: {
    m_goToSessions() {
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({ name: "sessions" });
    },
  },
};
</script>

<style>
</style>